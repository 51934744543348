<template>
	<StudentOverview></StudentOverview>
</template>

<script>
import StudentOverview from "@/components/shared/student/Overview.vue";

export default {
	name: "AdminStudentOverview",

	components: {
		StudentOverview,
	},
};
</script>

<style></style>
